import { Reviews } from "src/types/models";
import * as s3Client from "src/services/s3Client.service";

/**
 * Delete undefined keys of the object
 * @param {object} object
 * @returns object
 */
export const deleteUndefined = (obj: Record<string, unknown>) => {
  return Object.keys(obj)
    .filter((key) => obj[key] === undefined)
    .forEach((key) => delete obj[key]);
};

export const formatCurrency = (value: number, decimals = 0) => {
  const formatter = Intl.NumberFormat("en-US");
  return formatter.format((+value).toFixed(decimals));
};

/**
 * Return reviews ordered by option
 * @param reviews
 * @param option
 * @returns reviews orders by option
 */
type sortingOptionType = "RECENT" | "OLDEST";
export function sortReviewsByDate(
  reviews: Reviews[],
  sortingOption?: sortingOptionType
): Reviews[] {
  if (!reviews || reviews.length === 0) {
    return [];
  }
  return reviews.sort((a, b) => {
    switch (sortingOption) {
      case "OLDEST":
        return (
          new Date(a.modified_at).getTime() - new Date(b.modified_at).getTime()
        );
      case "RECENT":
      default:
        return (
          new Date(b.modified_at).getTime() - new Date(a.modified_at).getTime()
        );
    }
  });
}

//returns the url of the image
export async function uploadImage(fileUpload: string, imageData: string, opportunityId: string) {
  if (fileUpload !== "") {
    return await s3Client.uploadImage(fileUpload, imageData, opportunityId);
  } else {
    return imageData;
  }
}

  //take a date and return a new date object representing the same day but with the time set to midnight in the UTC timezone. 
  //input Sun Feb 18 2024 00:00:00 GMT+0300
  //output Sun Feb 18 2024 03:00:00 GMT+0300
  //which is equivalent to Sun Feb 18 2024 00:00:00 UTC
  //the output is being converted to ISOString
  export const offsetTimeZone = (date) => {
    const dateInUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    dateInUTC.setUTCHours(0, 0, 0, 0);
    return dateInUTC.toISOString();
  }